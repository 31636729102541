import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga";

const frameUrl = "https://rzekl.com/g/1e8d114494ad02b5823516525dc3e8/?subid=start-onbording&ulp=https%3A%2F%2Fwww.aliexpress.com%2Fitem%2F1005006485263513.html"

function OnBoarding(props) {
  const {t} = useTranslation(['welcome']);

  function redirect() {
    ym("reachGoal", "ONB_start_click");
    ReactGA.event({
      category: 'Landing',
      action: 'ONB_start_click',
    })
    document.location = frameUrl;
  }

  return [
    <div className="main-frame" key={1}>
      <div className={"support_popup_overlay open onboarding"}/>
      <div className={"onboarding-button"}>
        <div className={"onboarding-button-title"}>
          <h1>{t("onBoarding.title")}</h1>
          <p><Trans i18nKey="onBoarding.p1" t={t}/></p>
          <p className={'last-subtitle'}><Trans i18nKey="onBoarding.p2" t={t}/></p>
        </div>
        <div className="alihelper_plugin_wrap rating_calculated alihelper_plugin_wrap__rating--hight alihelper_plugin_wrap--done tabs_inited" onClick={redirect}>
        <div className="plugin_body plugin_body--button open plugin_body--price_down" >
          <nav>
            <div className="nav_item" id="alihelper_rating_mini">
              <a className="seller_rating" href={frameUrl} onClick={redirect}>
                <p>{t("pluginButton.rating")}</p>
              <span className="alihelper_rating_result_score">93%</span>
              </a>
              <span className="price_tooltip price_tooltip--done"/>
            </div>
          </nav>
        </div>
      </div>
      </div>
    </div>
  ]
}

export default OnBoarding;
